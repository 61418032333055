import mapSliceToComponent from '~/imports/home_page'
import { PAGE } from '~/lib/fetch-links'
import { STORYBLOK_TYPES } from '~/lib/storyblok-types'

import SliceManager from '~/components/Abstracts/SliceManager'
import { PageViewAnalytics } from '~/components/PageViewAnalytics'

import { getStaticPropsFunction } from '~/data/page-data'
import { DefaultPageData } from '~/data/page-data/serializer'

function HomePage({ slices }: DefaultPageData) {
  return (
    <>
      <SliceManager
        mapSliceTypeToComponent={mapSliceToComponent}
        slicesData={slices}
      />
      <PageViewAnalytics template="index" />
    </>
  )
}

export default HomePage

export const getStaticProps = getStaticPropsFunction({
  type: STORYBLOK_TYPES.HOMEPAGE,
  fetchLinks: PAGE,
})

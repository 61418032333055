import classnames from 'classnames/bind'
import { m, useMotionValue } from 'framer-motion'
import React, { useRef } from 'react'

import {
  useIsomorphicLayoutEffect,
  useMeasure,
} from '@unlikelystudio/react-hooks'

import { VideoState } from '~/components/Abstracts/VideoPlayer/hooks/useVideoState'

import useRelativePositions from '~/hooks/useRelativePosition'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ProgressBarProps = Pick<
  VideoState,
  'videoProgress' | 'videoDuration' | 'videoHandleSeekTo'
> & {
  className?: string
  backgroundColor?: string
  color?: string
  height?: number
}

function ProgressBar({
  className,
  videoProgress,
  videoDuration,
  videoHandleSeekTo,
  backgroundColor,
  color,
  height,
}: ProgressBarProps) {
  const videoProgressWrapper = useRef<HTMLDivElement>()

  const progessBarBounds = useMeasure(videoProgressWrapper)
  const { position: mousePosition, bindEvents } = useRelativePositions()
  const scaleX = useMotionValue(0)

  useIsomorphicLayoutEffect(() => {
    !isNaN(videoProgress) &&
      videoProgressWrapper?.current &&
      scaleX.set(videoProgress)
  }, [videoProgress])

  useIsomorphicLayoutEffect(() => {
    if (!progessBarBounds) return
    const { width } = progessBarBounds
    if (!width) return
    const percent = mousePosition.x / width
    videoHandleSeekTo(percent * videoDuration)
  }, [mousePosition])

  return (
    <div
      className={cx(css.ProgressBar, className)}
      ref={videoProgressWrapper}
      {...bindEvents}>
      <div
        className={cx(css.videoProgressContent)}
        style={{
          backgroundColor,
          height: `${height}px`,
        }}>
        <m.div
          className={cx(css.videoProgressBar)}
          style={{ backgroundColor: color, scaleX }}
        />
      </div>
    </div>
  )
}

ProgressBar.defaultProps = {
  color: '#000000',
  backgroundColor: '#0000001a',
  height: '2',
}

export default ProgressBar

import { STORYBLOK_SLICES } from '~/lib/storyblok-types'

import BigTitle from '~/components/Slices/BigTitle'
import EditorialTitleParagraphCta from '~/components/Slices/EditorialTitleParagraphCta'
import HeroSlider from '~/components/Slices/HeroSlider'
import HeroVideo from '~/components/Slices/HeroVideo'
import ProductsGrid from '~/components/Slices/ProductsGrid'
import ProductsPinsPush from '~/components/Slices/ProductsPinsPush'
import PushCategories from '~/components/Slices/PushCategories'
import PushGeneric from '~/components/Slices/PushGeneric'
import PushSocial from '~/components/Slices/PushSocial'
import PushStores from '~/components/Slices/PushStores'
import PushTypeListing from '~/components/Slices/PushTypeListing'
import ThreeColumnsImageWysiwyg from '~/components/Slices/ThreeColumnsImageWysiwyg'
import TwoColumnsImageTitleWysiwygs from '~/components/Slices/TwoColumnsImageTitleWysiwygs'

export default {
  [STORYBLOK_SLICES.HERO_SLIDER]: HeroSlider,
  [STORYBLOK_SLICES.PUSH_CATEGORIES]: PushCategories,
  [STORYBLOK_SLICES.PUSH_GENERIC]: PushGeneric,
  [STORYBLOK_SLICES.PUSH_STORES]: PushStores,
  [STORYBLOK_SLICES.PUSH_SOCIAL]: PushSocial,
  [STORYBLOK_SLICES.PRODUCTS_GRID]: ProductsGrid,
  [STORYBLOK_SLICES.PUSH_DESIGNERS]: PushTypeListing,
  [STORYBLOK_SLICES.BIG_TITLE]: BigTitle,
  [STORYBLOK_SLICES.EDITORIAL_TITLE_PARAGRAPH_CTA]: EditorialTitleParagraphCta,
  [STORYBLOK_SLICES.PRODUCTS_PINS_PUSH]: ProductsPinsPush,
  [STORYBLOK_SLICES.TWO_COLUMNS_IMAGE_TITLE_WYSIWYGS]:
    TwoColumnsImageTitleWysiwygs,
  [STORYBLOK_SLICES.THREE_COLUMNS_IMAGE_WYSIWYG]: ThreeColumnsImageWysiwyg,
  [STORYBLOK_SLICES.HERO_VIDEO]: HeroVideo,
}

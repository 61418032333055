import classnames from 'classnames/bind'
import React from 'react'

import { VideoState } from '~/components/Abstracts/VideoPlayer/hooks/useVideoState'

import MuteUnmuteButton, { MuteUnmuteButtonProps } from './MuteUnmuteButton'
import PlayPauseButton, { PlayPauseButtonProps } from './PlayPauseButton'
import ProgressBar, { ProgressBarProps } from './ProgressBar'
import css from './styles.module.scss'

const cx = classnames.bind(css)

type VideoControlsTheme = {
  playPauseButton: {
    color?: string
  }
  muteUnmuteButton: {
    color?: string
  }
  progressBar: {
    backgroundColor?: ProgressBarProps['backgroundColor']
    color?: ProgressBarProps['color']
  }
}

export type VideoControlsProps = Omit<
  VideoState,
  | 'videoInView'
  | 'videoStarted'
  | 'videoIsReady'
  | 'videoIsDOMReady'
  | 'videoControls'
> &
  Pick<PlayPauseButtonProps, 'pauseIcon' | 'playIcon'> &
  Pick<MuteUnmuteButtonProps, 'mutedIcon' | 'soundIcon'> & {
    className?: string
    playPauseButtonClassName?: string
    muteUnmuteButtonClassName?: string
    progressBarClassName?: string
    theme?: VideoControlsTheme
  }

function VideoControls({
  className,
  videoMuted,
  videoPaused,
  videoProgress,
  videoDuration,
  videoIsLoading,
  videoHandlePausePlay,
  videoHandleMuteUnmute,
  videoHandleSeekTo,
  videoIsHover,
  playPauseButtonClassName,
  muteUnmuteButtonClassName,
  progressBarClassName,
  theme,
  playIcon,
  pauseIcon,
  soundIcon,
  mutedIcon,
}: VideoControlsProps) {
  return (
    <div
      className={cx(css.VideoControls, className, {
        hide: !videoIsHover,
      })}>
      <PlayPauseButton
        className={cx(css.playpausebutton, playPauseButtonClassName)}
        videoHandlePausePlay={videoHandlePausePlay}
        videoPaused={videoPaused}
        videoIsLoading={videoIsLoading}
        color={theme?.playPauseButton?.color || '#000000'}
        playIcon={playIcon}
        pauseIcon={pauseIcon}
      />

      <MuteUnmuteButton
        className={cx(css.muteunmutebutton, muteUnmuteButtonClassName)}
        videoHandleMuteUnmute={videoHandleMuteUnmute}
        videoMuted={videoMuted}
        color={theme?.muteUnmuteButton?.color || '#000000'}
        soundIcon={soundIcon}
        mutedIcon={mutedIcon}
      />
      <ProgressBar
        className={cx(css.progressbar, progressBarClassName)}
        videoProgress={videoProgress}
        videoDuration={videoDuration}
        videoHandleSeekTo={videoHandleSeekTo}
        backgroundColor={theme?.progressBar?.backgroundColor || '#0000001a'}
        color={theme?.progressBar?.color || '#000000'}
      />
    </div>
  )
}

VideoControls.defaultProps = {}

export { PlayPauseButton, MuteUnmuteButton, ProgressBar }
export type { PlayPauseButtonProps, MuteUnmuteButtonProps, ProgressBarProps }
export default VideoControls

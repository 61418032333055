import { ComponentType } from 'react'
import { TStoryblokSliceKey } from '~/lib/storyblok-types'

export type TComponentType = ComponentType<any>
export type TSerializerType = (slice: any, locale: string, opts: any) => any

export type RenderComponentFromImportType = Partial<
  Record<TStoryblokSliceKey, TComponentType>
>
export type RenderSerializerFromImportType = Partial<
  Record<TStoryblokSliceKey, TSerializerType>
>

export type SliceData = {
  type: TStoryblokSliceKey
  data: ReturnType<TSerializerType>
}

export type SliceManagerProps = {
  slicesClassName?: string
  mapSliceTypeToComponent: RenderComponentFromImportType
  slicesData: SliceData[]
}

function SliceManager({
  slicesClassName,
  mapSliceTypeToComponent,
  slicesData,
}: SliceManagerProps) {
  return (
    <>
      {slicesData?.map((sliceData, index) => {
        const SliceComponent = mapSliceTypeToComponent?.[sliceData?.type]

        if (!SliceComponent) return null
        return (
          <SliceComponent
            key={index}
            className={slicesClassName}
            index={index}
            {...sliceData?.data}
          />
        )
      })}
    </>
  )
}

SliceManager.defaultProps = {}

export default SliceManager

import classnames from 'classnames/bind'
import React from 'react'

import { ImageProps } from '~/components/Abstracts/Image'
import Video from '~/components/Abstracts/VideoPlayer/VideoPlayerBasic'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type HeroVideoProps = {
  className?: string
  videoSrc?: string
  image?: ImageProps
}

function HeroVideo({ className, videoSrc, image }: HeroVideoProps) {
  return (
    <div className={cx(className, css.HeroVideo)}>
      {videoSrc && (
        <Video
          className={css.video}
          src={videoSrc}
          poster={image?.src ?? null}
          loop
          muted
          autoPlay
          playsInline
        />
      )}
    </div>
  )
}

HeroVideo.defaultProps = {}

export default HeroVideo

import classnames from 'classnames/bind'
import React, { useRef, useState } from 'react'

import {
  useIsHover,
  useIsomorphicLayoutEffect,
  useSpring,
} from '@unlikelystudio/react-hooks'

import Video, { VideoProps } from '~/components/Abstracts/Video'

import VideoControls from '../VideoControls'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export type VideoPlayerBasicProps = VideoProps & {
  className?: string
}

function VideoPlayerBasic({ className, src, ...rest }: VideoPlayerBasicProps) {
  const [isPlaying, setIsPlaying] = useState<boolean>(rest.autoPlay)
  const [isMuted, setIsMuted] = useState<boolean>(rest.muted)
  const [metaDataLoaded, setMetaDataLoaded] = useState<boolean>(false)
  const [videoProgress, setVideoProgress] = useState<number>()
  const [duration, setDuration] = useState<number>()
  const [currentTime, setCurrentTime] = useState<number>()
  const videoRef = useRef<HTMLVideoElement>()
  const [isHover, mouseEvents] = useIsHover()

  const springConfig = {
    interpolation: 'basic',
    friction: 100,
    precision: 0.001,
    perfectStop: true,
  }
  const [setProgress] = useSpring({
    progress: 0,
    config: springConfig,
    onUpdate: (e, prev) =>
      e.progress !== prev.progress && setVideoProgress(e.progress),
  })

  const updateProgress = () => {
    const time = videoRef?.current?.currentTime
    setCurrentTime(time)
    const progress = currentTime / duration
    !isNaN(progress) && setProgress({ progress })
  }

  const onDurationChange = () => {
    videoRef?.current?.duration && setDuration(videoRef.current.duration)
  }

  useIsomorphicLayoutEffect(() => {
    onDurationChange()
  }, [metaDataLoaded])

  useIsomorphicLayoutEffect(() => {
    isPlaying ? videoRef?.current?.play() : videoRef?.current?.pause()
  }, [isPlaying])

  useIsomorphicLayoutEffect(() => {
    if (videoRef?.current) {
      isMuted ? (videoRef.current.volume = 0) : (videoRef.current.volume = 1)
    }
  }, [isMuted])

  return (
    <div className={cx(className, css.VideoPlayer)} {...mouseEvents}>
      <Video
        className={css.video}
        ref={videoRef}
        src={src}
        onTimeUpdate={updateProgress}
        onDurationChange={onDurationChange}
        onLoadedMetadata={() => setMetaDataLoaded(true)}
        {...rest}
        muted={isMuted}
      />
      <VideoControls
        className={css.controls}
        videoDuration={duration}
        videoProgress={videoProgress}
        videoIsHover={isHover}
        videoPaused={!isPlaying}
        videoMuted={isMuted}
        videoHandleSeekTo={(time) => console.log({ time })}
        videoHandleMuteUnmute={() => setIsMuted((prev) => !prev)}
        videoHandlePausePlay={() => setIsPlaying((prev) => !prev)}
        videoIsLoading={false}
        // To Do : fix progress bar
        progressBarClassName={css.progressBar}
      />
    </div>
  )
}

VideoPlayerBasic.defaultProps = {}

export default VideoPlayerBasic
